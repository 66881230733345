import React from 'react';
import iconClose from '@src/img/icon-close.png';
import iconMenu from '@src/img/icon-menu.png';
import { Menu } from '../Menu';

import './index.less';

export const MenuDrawer = ({
  selectedPagePath = '',
  isVisible,
  onOpen,
  onClose,
}: {
  selectedPagePath?: string;
  isVisible: boolean;
  onOpen: () => void;
  onClose: () => void;
}) => {
  return (
    <>
      <div
        className="menu-drawer-wrapper"
        style={{ transform: isVisible ? 'translateY(0)' : 'translateY(-100%)' }}
      >
        <img src={iconClose} className={'close-icon'} onClick={onClose} />
        <Menu isDrawer selectedPagePath={selectedPagePath} />
      </div>
      {!isVisible && (
        <img src={iconMenu} className={'open-icon'} onClick={onOpen} />
      )}
    </>
  );
};
